
import { computed, defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as Yup from 'yup'
import ApiService from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { AxiosRequestConfig } from 'axios'
import { useI18n } from 'vue-i18n'
import { event } from 'vue-gtag'

interface Answer {
    id?: number
    qId?: number
    text: string
    isCorrect: boolean
}

interface Question {
    id?: number
    text: string
    answers: Answer[]
}

interface Exercise {
    subject: string
    title: string
    module: string
    deadline: string
    marked: boolean
    trimester: string
    classrooms: string[]
    students?: string[]
    status: string
    questions: Question[]
}

interface Subject {
    _id: string
    name: string
    status: string
}

interface Module {
    _id: string
    name: string
    subjects: Subject[]
    status: string
}

interface ClassRoom {
    _id: string
    name: string
    subjects: Subject[]
}
interface Student {
    _id: string
    firstName: string
    lastName: string
    schoolarYearsHistory: string[]
}

export default defineComponent({
    name: 'edit-exercise',
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    props: {
        id: String,
    },
    setup() {
        const { t } = useI18n()
        const submitButtonRef = ref<null | HTMLButtonElement>(null)
        // eslint-disable-next-line
        const upload = ref<any>(null)
        const subjects = ref<Subject[]>([])
        const modules = ref<Module[]>([])
        const classRooms = ref<ClassRoom[]>([])

        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        const jwtTrimester = JwtService.getTrimester()
        let trimester = '1'
        if (jwtTrimester && jwtTrimester != '0') trimester = jwtTrimester
        ApiService.post('/teacher/classRommDetails', {
            query: {
                teacher: store.getters.currentUser._id,
                trimester: trimester,

            },
        })
            .then(({ data }) => {
                let subjectList: Subject[] = []
                const classrooms: string[] = []
                for (const classroom of data) {
                    subjectList.push(...classroom.subjects)

                    const classRoomSubjects: Subject[] = classroom.subjects

                    //remove duplicated
                    subjectList = subjectList.filter(
                        (subject, index) =>
                            subjectList.findIndex(
                                (s) => s._id == subject._id
                            ) === index &&
                            !modules.value.find((m) =>
                                m.subjects.find((ss) => ss._id == subject._id)
                            ) &&
                            subject.status !== 'inactive'
                    )

                    classRooms.value.push({
                        _id: classroom.classRoom._id,
                        name: classroom.classRoom.name,
                        subjects: classRoomSubjects,
                    })
                    classrooms.push(classroom.classRoom._id)
                }
                subjects.value = subjectList
                getStudents(classrooms)
            })
            .catch((e) => console.log(e))

        const homeworkID = route.params.id

        const spec = ref<string>('0')


        const exercise = ref<Exercise>({
            subject: '',
            title: '',
            module: '',
            deadline: '',
            marked: true,
            trimester,
            classrooms: [],
            students: [],
            status: '',
            questions: [
                { text: '', answers: [{ text: '', isCorrect: false }] },
            ],
        })

        const studentList = ref<any[]>([])
        const sy = window.localStorage.getItem('activeSchoolarYear')

        const getStudents = (classrooms: string[]) => {
            const match = {}
            match[`schoolarYearsHistory.${sy}`] = { $in: classrooms }
            ApiService.post('/students/filter', {
                query: { status: 'active' },
                aggregation: [
                    {
                        $match: match,
                    },
                    {
                        $set: {
                            classRoom: {
                                $convert: {
                                    input: '$schoolarYearsHistory.' + sy,
                                    to: 'objectId',
                                    onError: null,
                                    onNull: null,
                                },
                            },
                        },
                    },
                    {
                        $project: {
                            _id: 1,
                            firstName: 1,
                            lastName: 1,
                            schoolarYearsHistory: 1,
                        },
                    },
                ],
            })
                .then(({ data }) => {
                    studentList.value = data
                })
                .catch((e) => console.log(e))
        }

        const validationSchema = Yup.object().shape({
            title: Yup.string().required(t('course.requiredQuestion')),
            subject: Yup.string().required(t('course.requiredSubject')),
            students: Yup.array().test(
                'required-students',
                t('course.requiredStudents'),
                (value) => {
                    if (spec.value == '0') return true
                    if (
                        spec.value == '1' &&
                        (value?.length == 0 || value == undefined)
                    )
                        return false
                    else return true
                }
            ),
            classrooms: Yup.array()
                .min(1, t('course.requiredClassroom'))
                .required(t('course.requiredClassroom')),
        })

        ApiService.get(`/lms/quiz/${homeworkID}`)
            .then(({ data }) => {
                exercise.value = {
                    subject: data.subject._id,
                    title: data.title,
                    module: data.module,
                    marked: data.marked,
                    deadline: data.deadline,
                    trimester: data.trimester ? data.trimester : '0',
                    classrooms: data.classrooms,
                    students: data.students,
                    status: data.status,
                    questions: data.questions,
                }

                if (data.students && data.students.length != 0)
                    getStudents(data.students)

                if (data.status == 'validated') router.push('/404')
            })
            .catch((e) => {
                console.log(e)
            })

        // eslint-disable-next-line
        const uploadFiles = ref<any>([])

        const updateUploadFiles = (file, files) => {
            uploadFiles.value = files
        }

        const submit = () => {
            event('Edit quiz', {
                event_category: 'Exercise',
                event_label: 'Exercise section',
                value: 1,
            })

            let test = false

            exercise.value.questions.map((question: Question) => {
                if (question.text == '') {
                    test = true
                    return
                }
                question.answers.map((answer: Answer) => {
                    if (answer.text == '') {
                        test = true
                        return
                    }
                })
            })

            if (test)
                return Swal.fire({
                    text: t('course.pleaseFillOrDeleteAnswers'),
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: t('course.tryAgain'),
                    customClass: {
                        confirmButton: 'btn fw-bold btn-light-danger',
                    },
                })

            // eslint-disable-next-line
            const data: any = {}

            if (!exercise.value.deadline) exercise.value.deadline = ''

            if (
                exercise.value.students &&
                exercise.value.students?.length != 0
            ) {
                const students: string[] = []
                exercise.value.students?.forEach((st: string) => {
                    students.push(st)
                })
                data.students = students
            }

            for (const name in exercise.value)
                if (!['classrooms', 'status', 'students'].includes(name))
                    data[name] = exercise.value[name]

            if (exercise.value.classrooms.length != 0) {
                const classrooms: string[] = []
                exercise.value.classrooms.forEach((cr: string) => {
                    classrooms.push(cr)
                })
                data.classrooms = classrooms
            }

            if (!submitButtonRef.value) {
                return
            }

            let questionId = 0

            exercise.value.questions = exercise.value.questions.map(
                (question) => {
                    question.id = questionId++
                    let answerId = 0
                    question.answers = question.answers.map((answer) => {
                        answer.qId = question.id
                        answer.id = answerId++
                        return answer
                    })
                    return question
                }
            )

            data.questions = exercise.value.questions

            //Disable button
            submitButtonRef.value.disabled = true
            // Activate indicator
            submitButtonRef.value.setAttribute('data-kt-indicator', 'on')

            ApiService.patch(
                `/lms/quiz/${homeworkID}`,
                data as AxiosRequestConfig
            )
                .then(() => {
                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false

                        submitButtonRef.value?.removeAttribute(
                            'data-kt-indicator'
                        )
                    }

                    Swal.fire({
                        text: t('course.editedExerciseInfo'),
                        icon: 'success',
                        buttonsStyling: false,
                        confirmButtonText: t('course.okay'),
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                    }).then(() => {
                        router.push(`/quiz/${homeworkID}`)
                    })
                })
                .catch(() => {
                    Swal.fire({
                        text: t('course.errorText'),
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: t('course.tryAgain'),
                        customClass: {
                            confirmButton: 'btn fw-bold btn-light-danger',
                        },
                    })
                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false

                        submitButtonRef.value?.removeAttribute(
                            'data-kt-indicator'
                        )
                    }
                })
        }

        const clearStudents = () => {
            exercise.value.students = []
        }

        const avaliableClassRooms = computed(() =>
            classRooms.value.filter((c: ClassRoom) =>
                c.subjects.find((s: Subject) => s._id == exercise.value.subject)
            )
        )

        const avaliableStudents = computed(() =>
            studentList.value.filter((s: Student) =>
                exercise.value.classrooms.includes(
                    s.schoolarYearsHistory[sy as string]
                )
            )
        )

        return {
            t,
            homeworkID,
            subjects,
            modules,
            spec,
            clearStudents,
            avaliableClassRooms,
            avaliableStudents,
            upload,
            exercise,
            validationSchema,
            submit,
            submitButtonRef,
            studentList,
            updateUploadFiles,
        }
    },
})
